import React, { useState } from 'react';
import Right from '../assets/icons/Keyboard_arrow_right.svg';
import Left from '../assets/icons/keyboard_arrow_left-slider.svg';
const IMG_URL = process.env.REACT_APP_FILE_BASE_URL || "";

const ImageSlider = ({ images }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(startIndex);
console.log('img', images)
  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  const handleNextClick = () => {
    setStartIndex(startIndex + 3);
  };

  const handlePrevClick = () => {
    setStartIndex(Math.max(startIndex - 3, 0));
  };

  return (
    <div className="container">
      <div id="imageCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={IMG_URL+images[currentSlide]?.url}
              className="d-block w-100"
              alt={`Product Image ${currentSlide + 1}`}
              style={{ maxHeight: '500px', objectFit: 'contain' }}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3 align-items-center">
        <div className="col-1">
          <button className="cta" onClick={handlePrevClick} disabled={startIndex === 0}>
            <img src={Left} alt="left icon" />
          </button>
        </div>

        <div className="col-10">
          <div className="thumbnails-container d-flex">
            {images.slice(startIndex, startIndex + 3).map((image, index) => (
              <div key={index} className="thumbnail-wrapper">
                <img
                  src={IMG_URL + image.url}
                  className={`img-thumbnail ${index === currentSlide % 3 ? 'active-thumbnail' : ''}`}
                  alt={`Thumbnail ${index + startIndex + 1}`}
                  onClick={() => handleSlideChange(index + startIndex)}
                  style={{ maxHeight: '80px', objectFit: 'cover' }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="col-1">
          <button className="cta" onClick={handleNextClick} disabled={startIndex + 3 >= images.length}>
            <img src={Right} alt="right icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
