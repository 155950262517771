import './login.scss'
import Logo from '../../assets/icons/logo.svg'
import React, {useState} from 'react';

import FormChange from '../../components/changePassword';

const ChangePassword = () => {
   
return(
    <div className="container-fluid login min-vh-10">
        <div className="row">
            <div className="col-md-6">
                <div className="left">
                  
                    <div className="bloc">
                    <img src={Logo} alt='logo' className='logo'/>
                    <h1 className="text-white mt-24">Bienvenue !</h1>
                    <p className='text-white mt-20'>Nous avons le plaisir de vous annoncer l’ouverture de la plateforme de commande des Cadeaux Client 2024. Pour rappel, 
                    vous avez jusqu’au 12 janvier 2024 inclus, pour commander vos cadeaux 2024.</p>
                    </div>
                    
                </div>
            </div>
            <div className="col-md-6 right">
                <FormChange/>
            </div>
        </div>
    </div>
)
}
export default ChangePassword;