import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import Services from "../../services/global.service";

function DeletePopup({ id, name, endpoint }) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  
  const deleteline = () => {
    Services.deleteByid(id, endpoint).then(
      (response) => {
        console.log("res", response.data);
        if (response.data.status === 1) {
          setModal(!modal)
          window.location.reload(false);
        } else {

        }
        // navigate("/catalogue");
      },
      (error) => {
        console.log("inputs not valide", error.response.data.message);
        return;
      }
    );
  };

  return (
    <div>
      <DeleteIcon onClick={toggle} className="cta-retour" />
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Supprimer {name}</ModalHeader>
        <ModalBody>
          Est-ce que vous êtes sûr de vouloir supprimer cet enregistrement? ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={ () => deleteline() }>
            Confirmer
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Annuler
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DeletePopup;
