import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@mui/material';
import './profil.scss';
import AuthService from '../../services/auth.service';
import Layout from '../Layout';
import Services from '../../services/global.service';

import { toast } from 'react-toastify';
const UpdateInformation = () => {
  const [info, setInfo] = useState({
    nom: '',
    prenom: '',
    poste: '',
    bunit: '',
    email: '',
    phone: '',
  });

  const getMyProfil = () => {
    AuthService.profil()
      .then((res) => {
        console.log(res);
        setInfo(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMyProfil();
  }, []);
  const [errors, setErrors] = useState({
    phone: '',
    // Add other fields if needed
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
    // Clear the error when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Implement your logic to update the information using AuthService or your API
    console.log('Updated Information:', info);
    const phoneRegex = /^\+[0-9]{11}$/;
    if (!phoneRegex.test(info.phone)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Veuillez entrer un numéro de téléphone valide.',
      }));
      return; // Exit the function if the phone number is not valid
    }


    const data={
      nom: info.nom,
      prenom: info.prenom,
      phone: info.phone,
      poste: info.poste,
      ids: info.id
    }
    Services.create(data, "users/update").then(
      (response) => {
        console.log("res", response);
        if (response.data.status && response.data.status === 1) {
          toast.success('Profil updated successfully!', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast.error('Failed to update profil2. Please try again.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        // navigate("/catalogue");
      },
      (error) => {
        console.log("inputs not valide", error);
        toast.error('Failed to update profil. Please try again.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    );
  };

  return (
    <Layout>
    <div className="container profil min-vh-80 mt-32">
      <h2>Modifier Informations personnelles</h2>
      <form onSubmit={handleSubmit}>
        <div className="row mt-24">
          <div className="col">
            <TextField
              label="Nom"
              variant="filled"
              fullWidth
              name="nom"
              value={info.nom}
              onChange={handleChange}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
               <div className="error-text">{errors.nom}</div>
          </div>
          <div className="col">
            <TextField
              label="Prénom"
              variant="filled"
              fullWidth
              name="prenom"
              value={info.prenom}
              onChange={handleChange}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
               <div className="error-text">{errors.prenom}</div>
          </div>
          <div className="col">
            <TextField
              label="Poste"
              variant="filled"
              fullWidth
              name="poste"
              value={info.poste}
              onChange={handleChange}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
               <div className="error-text">{errors.poste}</div>
          </div>
          <div className="col">
            <TextField
              label="Business unit"
              variant="filled"
              readOnly
              fullWidth
              name="bunit"
              value={info.bunit}
              onChange={handleChange}
              InputProps={{
                readOnly: true, 
              }}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
          </div>
        </div>
        <div className="row mt-16">
          <div className="col">
            <TextField
              label="Adresse email"
              variant="filled"
              fullWidth
              name="email"
              value={info.email}
              onChange={handleChange}
              InputProps={{
                readOnly: true, 
              }}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
          </div>
          <div className="col">
            <TextField
              label="Téléphone"
              variant="filled"
              fullWidth
              name="phone"
              value={info.phone}
              onChange={handleChange}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              
              className="input-aug"
            />
               <div className="error-text">{errors.phone}</div>
          </div>
          <div className="col"></div>
          <div className="col"></div>
        </div>
       
        <button
          type="submit"
          
          className="btn-primary mt-32"
        >
          Modifier
        </button>
      </form>
    </div>
    </Layout>
  );
};

export default UpdateInformation;
