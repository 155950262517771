import Layout from "../../LayoutAdmin";
import Services from "../../../services/global.service";
import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link } from "react-router-dom";
import BreadcrumbPage from "../../../components/breadcrump";
import DeletePopup from "../../../components/admin/deletePopup";
import Datatables from "./../../../components/datatables";
import Papa from 'papaparse';
import { read, utils } from 'xlsx';
import { toast } from "react-toastify";
const addCTA = (param) => {
  return (
    <div className="action">
      <Link to={`/admin/users/edit/${param.id}`}>
        <BorderColorIcon />
      </Link>
      <DeletePopup
        id={`${param.id}`}
        name={`${param.nom}`}
        endpoint="users/delete"
      />
    </div>
  );
};

const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: 10,
  },
  {
    name: "Nom",
    selector: (row) => row.nom,
    sortable: true,
  },
  {
    name: "Prénom",
    selector: (row) => row.prenom,
    sortable: true,
  },
  {
    name: "Business Unit",
    selector: (row) => row.bunit,
    sortable: true,
  },
  {
    name: "Poste",
    selector: (row) => row.poste,
    sortable: true,
  },
  {
    name: "Action",
    cell: (param) => addCTA(param),
  },
];


let rows = [];

const ListUsers = () => {
  const [values, setValues] = useState([]);
  const [data, setdata] = useState();
  const [parsedData, setParsedData] = useState([]);
  const [isReading, setIsReading] = useState(false);
  
  const handleFileChange = useCallback(e => {
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function(e) {
      var data = new Uint8Array(e.target.result);
      var workbook = read(data, { type: "array" });
      var firstSheet = workbook.SheetNames[0];
      setIsReading(false);
      const elements = utils.sheet_to_json(workbook.Sheets[firstSheet]);
      console.log("Excel read OK!");
      console.log("Found " + elements.length + " elements");
      console.log("JSON ", JSON.stringify(elements));
      console.log("JSON size", JSON.stringify(elements).length, "bytes");
      setParsedData(elements)
    };
    setIsReading(true);
    reader.readAsArrayBuffer(file);
  }, []);
  const handleSave=()=>{
    console.log('save',parsedData)
    Services.create({data:parsedData},'users/import')
    .then((res)=>{
      toast.success('Fichier importé !', {
        position: 'top-right',
        autoClose: 3000, // Close the toast after 3000 milliseconds (3 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setTimeout(() => {
        // Uncomment the line below if needed
         window.location.reload();
      }, 3000);
    })
    .catch((err)=>{
      toast.error('Erreur lors de l importation !!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    })
  }
  useEffect(() => {
    Services.getAll("users/list").then(
      (response) => {
        if (response.data.users) {
          setdata(response.data.users);
          response.data.users.forEach((element) => {
            rows = response.data.users;
          });
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);

  return (
    <Layout>
      <div className="container min-vh-80">
        <BreadcrumbPage
          parent="Utilisateurs"
          page="Liste des Utilisateurs"
          sub=""
        />

        <div className="row">
          <div className="col-md-6">
            <h1>Liste des Utilisateurs</h1>
        
          </div>
          <div className="col-md-6 right-aj">
            <a className="btn-primary" href="/admin/users/add">
              Ajouter
            </a>
            
          </div>
        </div>
        <div className="my-2 text-end">
            {/* File Uploader */}
            <input type="file" accept=".xls, .xlsx" onChange={handleFileChange} /> <button className="btn-primary" onClick={handleSave}>Importer</button>
              {isReading ? <p>Reading...</p> : null}
          </div>
        <div className="row">
          <div className="col-md-12">
            <Datatables columns={columns} data={rows} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ListUsers;
