import Layout from "../../LayoutAdmin";
import Services from "../../../services/global.service";
import { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link } from "react-router-dom";
import BreadcrumbPage from "../../../components/breadcrump";
import DeletePopup from "../../../components/admin/deletePopup";
import Datatables from "./../../../components/datatables";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const IMG_URL = process.env.REACT_APP_FILE_BASE_URL || "";

const RenderProfileImage = (param) => {
  if (param) {
    return (
      <>
        <img
          variant="rounded"
          className="table-photo"
          src={IMG_URL + "/" + param?.products_photos[0]?.url}
        />
      </>
    );
  }
};

const addCTA = (param) => {

  return (
    <div className="action">
      <Link to={`/admin/products/view/${param?.id}`}>
        <RemoveRedEyeIcon />
      </Link>
      <Link to={`/admin/products/edit/${param?.id}`}>
        <BorderColorIcon />
      </Link>
      <DeletePopup
        id={`${param?.id}`}
        name={`${param?.nom}`}
        endpoint="product/delete"
      />
    </div>
  );
};

const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: 10,
  },
  {
    name: "Photo",
    cell: (param) => RenderProfileImage(param),
  },
  {
    name: "Nom produit",
    selector: (row) => row.nom,
    sortable: true,
  },
  {
    name: "Référence",
    selector: (row) => row.reference,
    sortable: true,
  },
  {
    name: "Prix (HT)",
    selector: (row) => row.prix + " €",
    sortable: true,
  },
  {
    name: "Action",
    cell: (param) => addCTA(param),
  },
];

let rows = [];

const ListProducts = () => {
  const [parsedData, setParsedData] = useState([]);

  const [data, setdata] = useState();
 
 
  useEffect(() => {
    Services.getAll("product/list").then(
      (response) => {
        if (response.data.products) {
          setdata(response.data.products);
          response.data.products.forEach((element) => {
            rows = response.data.products;
          });
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);

  return (
    <Layout>
      <div className="container">
        <BreadcrumbPage parent="Produits" page="Liste des produits" sub="" />
        <div className="row">
          <div className="col-md-6">
            <h1>Liste Produits</h1>
          </div>
          <div className="col-md-6 right-aj">
            <a className="btn-primary" href="/admin/products/add">
              Ajouter
            </a>
          </div>
        </div>
       
        <div className="row">
          <div className="col-md-12">
            {/* <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            /> */}
            <Datatables columns={columns} data={rows} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ListProducts;
