import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL || "";

const token = localStorage.getItem('token')

const headersFiles = {
    headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+token, 
        'Access-Control-Allow-Origin': '*',
    },
};

const headersFilesMultipart = {
    headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer '+token,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    },
};


const getAll = (endpoint) => {
    return axios.get(API_URL + endpoint, headersFiles);
};
const getAllByData = (endpoint,data) => {
    
    return axios.post(API_URL + endpoint,data, headersFiles);
};
const getOne = (id, endpoint) => {
    return axios.get(API_URL +  endpoint + `/${id}`, headersFiles);
};

const create = (data, endpoint) => {
    return axios.post(API_URL +  endpoint, data, headersFiles);
};

const createMultipart = (data, endpoint) => {
    return axios.post(API_URL +  endpoint, data, headersFilesMultipart);
};


const deleteByid = (id, endpoint) => {
    return axios.post(API_URL + endpoint + `/${id}`,'', headersFiles);
};

const update = (data, id, endpoint) => {
    return axios.post(API_URL + endpoint + `/update/${id}`, data, headersFiles);
};

const updatewithoutID = (data, endpoint) => {
    return axios.post(API_URL + endpoint + `/update`, data, headersFiles);
};
const getByRole = (endpoint, role) => {
    
    return axios.get(API_URL + endpoint+ `/${role}`, headersFiles);
};


const Services = {

    getAll,getOne,create,createMultipart,deleteByid,update, updatewithoutID,getByRole,getAllByData

}
export default Services;
