import Layout from "../Layout"

import './profil.scss'
// import { makeStyles } from '@mui/styles';
import { useState } from "react";
import Sidebar from "./sidebar";
import Information from "./informations";
import ChangePasswordConnected from "./changePassword";
import Historique from "./historique";
import Adresses from "./addresses";
import { useParams } from "react-router-dom";
const Profil=()=>{
    const params=useParams()
    console.log('param',params.id)
    const [page, setPage] = useState(parseInt(params?.id) || 1);
    
    return(
    
       
     <Layout>
        <div className="container-fluid min-vh-80">
           
            <div className="row">
                <div className="col-auto"><Sidebar page={page} setPage={setPage}></Sidebar> </div>
              {page && 
               <div className="col w-sm-100 mt-48"> {page===1?<Information/> : page===2? <ChangePasswordConnected/>:page===3? <Historique/>:<Adresses/>}</div> }
            </div>
            
        </div>
       
     </Layout>
    
   
)
}
export default Profil;