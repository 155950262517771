import React, { useState } from 'react';
import { Alert } from 'reactstrap';

function Alerts({color, msg, visible, setVisible}) {
  

  const onDismiss = () => setVisible(false);

  return (
    <Alert color={color} isOpen={visible} toggle={onDismiss}>
      {msg}
    </Alert>
  );
}

export default Alerts;
