import React, { useState } from 'react'
import Logo from '../assets/icons/LOGO_LOXAM_4COLOR_BG_WHITE.svg'
import User from '../assets/icons/Profile.svg'
import Shopping from '../assets/icons/Shopping.svg'
import '../assets/styles/header.scss'
 import MenuIcon from '../assets/icons/Menu.svg'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import i18n from '../i18n/index';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Link } from 'react-router-dom'
import { Select, TextField } from '@mui/material'
const Header=()=>{
    const [open, setOpen]=useState(false)
    const handleClick=()=>{
        setOpen(!open)
    }
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        i18n.changeLanguage(e.target.value);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
    }
return(
    <header className='headers'>
        <div className="d-flex justify-content-between">
       <a href="/catalogue"><img src={Logo} alt="logo"/></a> 
        <div className="desktop">
            <a className='cta-header' href='/catalogue'>Catalogue produit</a>
            <a href="/profil/1"><img src={User} alt='user icon' className='ml-16'/></a>
            <a href="/panier"><img src={Shopping} alt='user icon' className='ml-16'/></a>
            {/* <select defaultValue={selectedLanguage} onChange={chooseLanguage} className='lang-select ml-16 select-control'>  
            <option value="fr">FR</option>
            <option value="en">EN</option>
            
        </select> */}
        {/*
         <Select
          labelId="demo-simple-select-filled-label "
          id="demo-simple-select-filled"
          value={selectedLanguage}
          className="input-aug lang-select"
          onChange={chooseLanguage}
        >
          
          <MenuItem value={'fr'}>FR</MenuItem>
          <MenuItem value={'en'}>EN</MenuItem>
          
        </Select>
        */}
                    
        </div>
        <div className="mobile">
        <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <button className='cta' {...bindTrigger(popupState)}>
          <img src={MenuIcon} alt="menu close open icon"/>
          </button>
          <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={popupState.close}>
          <Link to="/catalogue" className='cta'>Catalogue</Link>
        </MenuItem>
        <MenuItem onClick={popupState.close}>
          <Link to="/profil" className='cta'>Mon profil</Link>
        </MenuItem>
        <MenuItem onClick={popupState.close}>
          <Link to="/panier" className='cta'>Mon Panier</Link>
        </MenuItem>
      </Menu>
        </React.Fragment>
      )}
    </PopupState>
           
        </div>
        </div>
        
    </header>
)
}
export default Header;