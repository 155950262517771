import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import More from '../assets/icons/keyboard_arrow_bottom.svg'
const DataTable = ({ rows , data}) => {
  console.log('data', data)
  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (rowId) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(45, 42, 44, 1)',
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
    '&.expanded': {
      backgroundColor: 'white', // Set the background color for expanded rows
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
      <Table>
        <TableHead className='dark'>
          <TableRow>
            <StyledTableCell style={{ width: '20%' }}>Produit</StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>Livraison</StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>Facturation </StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>Quantité</StyledTableCell>
            <StyledTableCell style={{ width: '15%' }}>Prix Total HT</StyledTableCell>
            <StyledTableCell style={{ width: '5%' }}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <React.Fragment key={row.id}>
              <StyledTableRow
                className={expandedRow === row.id ? 'expanded' : ''}
                hover
                onClick={() => handleRowClick(row.id)}
              >
                <StyledTableCell style={{ width: '20%' }}>
                 <h5>{row?.article?.nom}</h5>
                 <p className="text-ref"> Ref: {row?.article?.reference} {row?.article?.fournisseur}</p>
                </StyledTableCell>
                <StyledTableCell style={{ width: '20%' }}>{row?.commandes_articles_adresses?.length} adresses</StyledTableCell>
                <StyledTableCell style={{ width: '20%' }}>{row?.commandes_articles_adresses? `${row?.commandes_articles_adresses.length} entité${row?.commandes_articles_adresses.length === 1 ? '' : 's'} de facturation`: 'Aucune entité de facturation'}</StyledTableCell>
                <StyledTableCell style={{ width: '20%' }}>{row?.qte}</StyledTableCell>
                <StyledTableCell style={{ width: '15%' }}>{row?.subtotal} € </StyledTableCell>
                <StyledTableCell style={{ width: '5%' }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row.id);
                    }}
                  >
                   <img src={More} alt="more icon" className={expandedRow === row.id ? 'open' : ''}/>
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>

              {expandedRow === row.id && (
                <TableRow>
                  <StyledTableCell colSpan={6}>
                    <Accordion expanded={true} elevation={0}>
                      <AccordionDetails>
                        {/* Additional details if needed */}
                        <Table>
                          <TableBody>
                            {row?.commandes_articles_adresses?.map((liv) => (
                              <TableRow key={liv?.adresse}>
                                <StyledTableCell style={{ width: '20%' }}></StyledTableCell>
                                <StyledTableCell style={{ width: '20%' }}>{liv?.commandes_adresse?.code} {liv?.commandes_adresse?.entite}</StyledTableCell>
                                <StyledTableCell style={{ width: '20%' }}>{liv?.commandes_adresse?.entite}</StyledTableCell>
                                <StyledTableCell style={{ width: '20%' }}>{liv?.qte}</StyledTableCell>
                                <StyledTableCell style={{ width: '15%' }}>{liv.subtotal} € </StyledTableCell>
                                <StyledTableCell style={{ width: '5%' }}></StyledTableCell>

                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  </StyledTableCell>
                </TableRow>
              )}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
