// ProductForm.js
import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Services from "./../../services/global.service";
import Alerts from "./Alerte";
import { TextField } from "@mui/material";

const validationSchema = Yup.object().shape({
  // Define validation rules for each field
  nom: Yup.string().required("Nom est obligatoire"),
  reference: Yup.string().required("Référence est obligatoire"),
  fournisseur: Yup.string().required("Fournisseur est obligatoire"),
  prix: Yup.number().required("Prix est obligatoire"),
  // photos: Yup.string().required("Photo est obligatoire"),
  // description: Yup.string().required("Description est obligatoire"),
  // ficheTechnique: Yup.string().required("Fiche technique est obligatoire"),
  quantiteParCarton: Yup.number().required("Quantité est obligatoire"),
  quantite: Yup.number().required("Quantité est obligatoire"),
});

const ProductForm = () => {
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [file, setFile] = useState();
  const [desc, setDesc] = useState();
  const [fiche, setFiche] = useState();
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState();

  const fileRef = useRef();

  return (
    <>
      <Alerts
        color={color}
        msg={message}
        visible={visible}
        setVisible={setVisible}
      />
      <Formik
        initialValues={{
          nom: "",
          reference: "",
          fournisseur: "",
          prix: "",
          photos: "",
          quantiteParCarton: "",
          quantite: "",
          ficheTechnique: "",
          description: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          // same shape as initial values
          console.log(values);
          setStatus("loading");
          setMessage("");

          const formData = new FormData();
          formData.append("nom", values.nom);
          formData.append("reference", values.reference);
          formData.append("images", file);
          formData.append("prix", values.prix);
          formData.append("fournisseur", values.fournisseur);
          formData.append("quantiteParCarton", values.quantiteParCarton);
          formData.append("quantite", values.quantite);
          formData.append("fiche", fiche);
          formData.append("description", desc);

          Services.createMultipart(formData, "product/store").then(
            (response) => {
              console.log("res", response.data.status);
              if (response.data.status === 1) {
                setMessage("Produit ajouté avec succès ");
                setVisible(true);
                setColor("success");
              } else {
                setMessage("Erreur d'ajout");
                setVisible(true);
                setColor("danger");
              }
              // navigate("/catalogue");
            },
            (error) => {
              console.log("inputs not valide", error.response.data.message);
              setMessage(error.response.data.message);
              return;
            }
          );
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          touched,
          values,
        }) => (
          <Form className="form-container">
            <div className="row mt-12">
              <div className="col">
                {/* form-floating */}

                <TextField
                  type="text"
                  id="nom"
                  name="nom"
                  label="Nom"
                  variant="standard"
                  error={!!touched.nom && !!errors.nom}
                  helperText={touched.nom && errors.nom}
                  defaultValue={values.nom}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <TextField
                  type="text"
                  id="reference"
                  name="reference"
                  label="Référence"
                  variant="standard"
                  error={!!touched.reference && !!errors.reference}
                  helperText={touched.reference && errors.reference}
                  defaultValue={values.reference}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-12">
              <div className="col">
                <TextField
                  type="text"
                  id="fournisseur"
                  name="fournisseur"
                  label="Fournisseur"
                  variant="standard"
                  error={!!touched.fournisseur && !!errors.fournisseur}
                  helperText={touched.fournisseur && errors.fournisseur}
                  defaultValue={values.fournisseur}
                  onChange={handleChange}
                />
              </div>
              <div className="col">
                <TextField
                  type="text"
                  id="prix"
                  name="prix"
                  label="Prix"
                  variant="standard"
                  error={!!touched.prix && !!errors.prix}
                  helperText={touched.prix && errors.prix}
                  defaultValue={values.prix}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row mt-12">
              <div className="col">
                <TextField
                  type="file"
                  id="photos"
                  name="file"
                  variant="standard"
                  label="Photos"
                  className="form-control mt-10"
                  innerRef={fileRef}
                  onChange={(event) => {
                    setFile(event.currentTarget.files[0]);
                  }}
                  error={!!touched.prix && !!errors.prix}
                  helperText={touched.prix && errors.prix}
                />
              </div>
              <div className="col">
                <TextField
                  type="text"
                  id="quantiteParCarton"
                  name="quantiteParCarton"
                  label="Quantité par carton"
                  variant="standard"
                  error={
                    !!touched.quantiteParCarton && !!errors.quantiteParCarton
                  }
                  helperText={
                    touched.quantiteParCarton && errors.quantiteParCarton
                  }
                  defaultValue={values.quantiteParCarton}
                  onChange={handleChange}
                /><br></br>
                <TextField
                  type="text"
                  id="quantite"
                  name="quantite"
                  label="Quantité Total"
                  variant="standard"
                  error={!!touched.quantite && !!errors.quantite}
                  helperText={touched.quantite && errors.quantite}
                  defaultValue={values.quantite}
                  onChange={handleChange}
                />
              </div>
            </div>
            <br></br>
            <div className="row mt-12">
              <div className="col">
                <label htmlFor="ficheTechnique">Fiche technique:</label>

                <ReactQuill
                  id="ficheTechnique"
                  name="ficheTechnique"
                  className="mt-10"
                  value={fiche}
                  onChange={setFiche}
                />
                {errors.ficheTechnique && touched.ficheTechnique ? (
                  <div>{errors.ficheTechnique}</div>
                ) : null}
              </div>
              <div className="col">
                <label htmlFor="description">Description:</label>
                <ReactQuill
                  id="description"
                  name="description"
                  className="mt-10"
                  value={desc}
                  onChange={setDesc}
                />
                {errors.description && touched.description ? (
                  <div>{errors.description}</div>
                ) : null}
              </div>
            </div>

            <div className="text-end">
              <button type="submit" className="btn-primary mt-20 ">
                Sauvegarder
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ProductForm;
