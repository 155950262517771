import '../assets/styles/footer.scss'
const Footer=({admin})=>{
    // console.log(admin)
return(
    <footer>
        <div className="d-flex justify-content-center">
            {!admin?
            <><div className="px-20 bl"><a href="/contact" className="cta">Contact</a></div>
            <div className=" px-20 bl"><a href="/mentions" className="cta">Mentions légales</a></div>
            <div className="px-20 bl"><a href="/pc" className="cta">Politique de confidentialité</a></div>
            <div className="px-20"><a href="/cgu" className="cta">CGU</a></div>
            </>
            :
            <p className='text-white'>Copyright LOXAM {new Date().getFullYear()}</p>
            }
            
        </div>
    </footer>
)
}
export default Footer