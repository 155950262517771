import LayoutAdmin from "../../LayoutAdmin";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import FormAddAdresse from '../../../components/admin/formAddAdresse';

const AddAdresses = () => {
  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Adresses"
          page="Liste des adresses"
          sub={"Ajouter adresse"}
          parentLink='/admin/adresses'
        />
        <Link to={`/admin/adresses`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>

        <h1 className="mt-20">Ajouter Adresse</h1>
        <FormAddAdresse />
      </div>
    </LayoutAdmin>
  );
};
export default AddAdresses;
