import Layout from "../Layout";
import Phone from "../../assets/icons/Phone.svg";
import Email from "../../assets/icons/Email.svg";
import "./contact.scss";
const Cgu = () => {
  return (
    <Layout>
      <div className="container min-vh-100 mt-32">
        <div className="mentions">
          <h1>Conditions générales d’utilisation</h1>

          <ol className="mt-24">
            <li>
              <h2>ARTICLE 1. Champ d’application</h2>
            </li>
            <p>
              Les présentes Conditions Générales d’Utilisation s'appliquent,
              sans restriction ni réserve à l’intégralité du site internet de
              prise de commandes de la société AUGUSTA. Elles régissent
              l’utilisation du site Augusta suivant :{" "}
              <a href="https://webshop.Augusta.fr/" className="cta">
                webshop.Augusta.fr
              </a>.<br/>
               Chaque Utilisateur doit impérativement lire et accepter les
              présentes conditions avant toute utilisation des sites internet de
              la Plateforme. Chaque utilisation de compte client implique
              l’acceptation des présentes Conditions sans réserve. Pour
              améliorer son fonctionnement et sa qualité, la société AUGUSTA
              peut être appelée à modifier unilatéralement les Conditions
              générales d’utilisation. La société AUGUSTA informera
              l’Utilisateur par tout moyen au moins 15 jours avant leur entrée
              en vigueur. En l'absence d'objection par l'Utilisateur dans ce
              délai, les modifications seront considérées comme étant lues et
              acceptées. En cas d'objection ou de refus, les CGU seront
              immédiatement résiliées et l'Utilisateur devra cesser d'utiliser
              la plateforme.
            </p>
            <li>
              <h2>ARTICLE 2. Services proposés</h2>{" "}
            </li>
            <p>
              Les services fournis par la société AUGUSTA comprennent :<br /> -
              L’accès et l’utilisation d’une plateforme de prise de commandes
              permettant de recevoir des services tiers .<br /> Les services
              proposés par la société AUGUSTA permettent la prise de commandes
              en ligne des clients de ladite société. Les comptes client
              sollicitant la plateforme génèrent une commande contenant les
              caractéristiques essentielles de celle-ci. La société AUGUSTA
              réceptionne les demandes et se sert de la plateforme pour traiter
              les données relatives à ces commandes.
              <br /> La société AUGUSTA agit uniquement en tant que fournisseur
              et support à la prise de commandes afin de permettre au client
              concerné de solliciter et de recevoir les produits proposés par
              les utilisateurs. Tous les utilisateurs en capacité d’utiliser la
              plateforme pour une prise de commande sont des employés de
              sociétés tierces clientes de la société AUGUSTA.
              <br />
              Par le biais d’un accord préalable avec AUGUSTA, ceux-ci
              s’engagent dans une relation contractuelle liant les Utilisateurs
              de la plateforme et la société AUGUSTA. La société AUGUSTA peut
              mettre à disposition sa plateforme à d’autres fournisseurs de ses
              clients pour centraliser la prise de commandes et s’engage à
              communiquer les informations liées aux prises de commandes à son
              client. Elle ne peut cependant pas être tenue pour responsable du
              refus d’un Prestataire de réaliser les commandes passées sur la
              plateforme après transmission des informations de commande.
            </p>
            <li>
              <h2> ARTICLE 3. Utilisation des services </h2>
            </li>
            <p>
              La plateforme de la société AUGUSTA sont accessibles sur la
              plupart des appareils mobiles modernes disposant d’une connexion
              internet et équipés de systèmes d’exploitation répandus tels
              qu’Android ou iOS. Il incombe aux utilisateurs d’obtenir l’accès
              au réseau nécessaire à l’utilisation de la plateforme et tous les
              tarifs et frais de votre prestataire de réseau mobile sont à la
              charge de l’Utilisateur, y compris pour les données afférentes à
              l’utilisation de la plateforme d’AUGUSTA. Il leur incombe
              également d’accéder à des appareils et systèmes d’exploitation
              compatibles nécessaires à l’utilisation des Applications mobiles
              et/ou sites Internet, y compris les mises à jour logicielles ou
              matérielles. Afin de pouvoir utiliser les services, l’Utilisateur
              doit s’enregistrer dans les applications mobiles et/ou sur les
              sites internet de la société Augusta et créer un compte ou
              demander aux équipes d’Augusta la création d’un compte. Au moment
              de l’inscription, l’Utilisateur doit fournir certaines
              informations personnelles pour s’inscrire notamment :
              <br />- Titre d’identité .
              <br />- Adresse postale .
              <br />- Adresse électronique .
              <br />- Numéro de téléphone.
            </p>
            <li>
              <h2>ARTICLE 4. Obligations de l’utilisateur</h2>
            </li>
            <p>
              L’Utilisateur s’engage à utiliser les services dans le respect des
              dispositions légales et pour les fins pour lesquelles ils ont été
              conçus. Tout usage frauduleux des Applications mobiles et/ou Sites
              internet sera sanctionné par la suppression immédiate du compte de
              l’utilisateur. Le compte de l’utilisateur est réservé au personnel
              autorisé. Ce dernier s’engage :<br />
              -A ne pas autoriser des tiers extérieurs à son organisation à
              utiliser son compte, ni à transférer son compte à un tiers; <br />
              -A veiller à ce que les informations relatives à son compte soient
              exactes, complètes et à jour ; <br />
              -A ne pas divulguer ses informations de connexion confidentielles
              ; <br />
              -A informer la société Augusta de tout risque d’usurpation de son
              identité sur le compte. <br />
              L’utilisateur s’engage également expressément à :<br />
              -Respecter les lois et règlements en vigueur et à ne pas porter
              atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers
              et à n’enfreindre aucune disposition législative ou réglementaire{" "}
              <br />
              -Ne pas publier de contenus injurieux, diffamant, dénigrant,
              calomnieux, contraire aux bonnes mœurs, contrefaisant, portant
              atteinte à l'ordre public ou aux droits de tiers, susceptible de
              porter atteinte aux droits, à la réputation et à l'image de la
              société Augusta ou d'un autre utilisateur, par quelque biais que
              ce soit <br />
              -Ne pas utiliser les Applications mobiles et/ou Sites Internet de
              manière frauduleuse ; <br />
              -Fournir à la société Augusta toutes les informations nécessaires
              à la bonne exécution des services et garantir leur exactitude ;{" "}
              <br />
              -Informer la société Augusta en cas de complication de toute
              nature, de tout problème avec un utilisateur ou lors de
              l’utilisation du site internet.
              <br />
              L’utilisateur comprend et accepte que sont strictement interdits :
              <br />
              -Tout comportement de nature à interrompre, suspendre, ralentir ou
              empêcher la continuité du site Internet ; <br />
              -Toute intrusion ou tentative d’intrusion dans les systèmes de la
              Société Augusta ;<br />
              -Tout détournement des ressources système du compte et des
              plateformes numériques ; <br />
              -Toute atteinte aux mesures de sécurité et d’authentification ;
              <br />
              -Tout manquement aux présentes conditions générales ; <br />
              -Toute copie et détournement de l’infrastructure numérique
              proposée par la société AUGUSTA‍ <br />
            </p>
           <li><h2>ARTICLE 5. Obligations de la plateforme</h2></li> 
            Les Utilisateurs peuvent
            déposer des offres ou des demandes de prestation sur le site
            internet. Après validation, la demande est transmise aux fabricants
            concernés et correspondant au mieux à la demande. Les utilisateurs
            reconnaissent et acceptent que la responsabilité de la plateforme
            est limitée à son rôle d’opérateur de Plateforme en ligne. <br/>En aucun
            cas la plateforme n’engagera sa responsabilité au titre des
            prestations réalisées car en sa qualité d’intermédiaire numérique,
            la plateforme n’est pas chargée de leur réalisation. Par conséquent,
            la responsabilité de la plateforme ne pourra être engagée pour les
            causes extérieures à son activité de plateforme en ligne et
            notamment dans les cas suivants :<br/> 
            -tout dommage direct ou indirect
            subi par un utilisateur ou un tiers au cours de l’exécution de la
            prestation ou en lien avec cette dernière ;<br/> 
            -en cas d’annulation ou
            de non-réalisation de la prestation par l’un ou l’autre des
            utilisateurs ;<br/> 
            - en cas d’inexactitude ou d’erreurs dans les
            informations déclarées par l’utilisateur ; <br/> 
            -en cas de violation des
            conditions générales par l’Utilisateur. <br/> 
            Par ailleurs, la Plateforme
            n’est pas responsable des difficultés ou impossibilités momentanées
            d’accès aux Applications mobiles et/ou Sites Internet, aux comptes
            ou aux services, si elles ont pour origine une faute d'un
            Utilisateur, des circonstances qui lui sont extérieures, la force
            majeure, ou encore qui seraient dues à des perturbations des réseaux
            de télécommunication ou aux limites inhérentes au fonctionnement
            d'un service sur Internet. ‍‍
            <li><h2>ARTICLE 6. Conditions financières</h2></li>
           <p> L’utilisation de la plateforme pour les utilisateurs est strictement</p>
            gratuit. 
            <li><h2>ARTICLE 7. Autonomie et indépendance des Parties</h2></li> 
            <p>La
            relation établie entre la Plateforme et les Utilisateurs est celle
            d’entreprises indépendantes et autonomes. Le Contrat ne contient
            aucune forme ni intention de constituer une société de droit ou de
            fait, les Parties étant dépourvues d’affection societatis. Les
            Utilisateurs agissent de manière libre et indépendante. Les parties
            s’engagent à toujours se comporter l’une envers l’autre comme des
            partenaires loyaux et de bonne foi et notamment à s’informer
            mutuellement de toute difficulté qu’elles pourraient rencontrer à
            l’occasion de l’exécution des services de la Plateforme.‍ </p> 
            <li><h2>ARTICLE 8.
            Propriété intellectuelle</h2></li><p>La Plateforme, l’Application, le Site et
            chacun des éléments qui les composent, de manière non limitative et
            non restrictive, ainsi que les droits de propriété intellectuelle
            associés, sont la propriété exclusive de la société Augusta. Toute
            reproduction ou représentation, intégrale ou partielle, de quelque
            nature que ce soit, de la Plateforme, du Site internet, ou de chacun
            des éléments qui les composent est strictement interdite.‍</p>
            <li><h2>ARTICLE 9. Protection des données personnelles</h2></li>  
            <p>En application de la loi
            n°78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin
            2018, il est rappelé que les données nominatives demandées aux
            Utilisateurs sont nécessaires à l’offre des services et à
            l'établissement des factures, notamment. Ces données peuvent être
            communiquées aux éventuels partenaires de la Plateforme chargés de
            l'exécution, du traitement, de la gestion et du paiement des
            commandes. Le traitement des informations répond aux exigences
            légales en matière de protection des données personnelles, le
            système d'information utilisé assurant une protection optimale de
            ces données. L’Utilisateur dispose, conformément aux réglementations
            nationales et européennes en vigueur d'un droit d'accès permanent,
            de modification, de rectification, d'opposition de portabilité et de
            limitation du traitement s'agissant des informations le concernant.</p>
            <li><h2>ARTICLE 10 – Droit applicable – Langue </h2></li>
            <p>Les présentes Conditions
            générales d’Utilisation et les opérations qui en découlent sont
            régies par le droit français. Elles sont rédigées en langue
            française. Dans le cas où elles seraient traduites en une ou
            plusieurs langues, seul le texte français ferait foi en cas de
            litige.</p>
          </ol>
        </div>
      </div>
    </Layout>
  );
};
export default Cgu;
