import { Paper, TableSortLabel, TextField } from "@mui/material";
import SortIcon from "../../assets/icons/Polygon 3.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Search from "../../assets/icons/Search.svg";
import Edit from "../../assets/icons/Edit.svg";

import { styled, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Adresses = () => {
  const [order, setOrder] = useState("asc");

  const [adresses, setAdresses] = useState([]);
  const [filteredAndSortedAdresses, setFilteredAndSortedAdresses] =
    useState(adresses);

  const [orderBy, setOrderBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [filteredAdresses, setFilteredAdresses] = useState(adresses);
  const getAdresses = () => {
    //adresses/owner => my adresses
    Services.getAll("adresses/owner")
      .then((res) => {
        console.log(res?.data);
        setAdresses(res?.data);
        setFilteredAdresses(res?.data);
        const sortedAddresses = res?.data?.sort((a, b) => a.code.localeCompare(b.code));
        setFilteredAndSortedAdresses(sortedAddresses);
        console.log("adr", adresses);
      })
      .catch((err) => {
        console.error("Error fetching addresses:", err);
      });
  };
  const handleSortOld = (property) => {
    const isAsc = orderBy === property && sortDirection === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setSortDirection(newOrder);
    console.log("property", property);
    const sorted = [...filteredAdresses].sort((a, b) => {
      if (property === "adr-fact") {
        const addressA = a?.livraison ? a.livraison.adresse : "";
        const addressB = b?.livraison ? b.livraison.adresse : "";

        // Additional check for undefined or null values
        if (addressA === undefined || addressB === undefined) {
          // Handle the case where either addressA or addressB is undefined
          return 0; // or handle it according to your sorting logic
        }

        // Handle empty values separately
        if (addressA === "" && addressB === "") {
          return 0; // Both are empty, consider them equal
        } else if (addressA === "") {
          return isAsc ? -1 : 1; // Empty values come first when ascending
        } else if (addressB === "") {
          return isAsc ? 1 : -1; // Empty values come last when descending
        } else {
          // Compare non-empty values using simple string comparison
          return isAsc
            ? addressA < addressB
              ? -1
              : 1
            : addressA > addressB
            ? -1
            : 1;
        }
      }

      if (property === "res-fact" && a.livraison && b.livraison) {
        return isAsc
          ? (a.livraison.responsable || "").localeCompare(
              b.livraison.responsable || ""
            )
          : (b.livraison.responsable || "").localeCompare(
              a.livraison.responsable || ""
            );
      } else {
        const valueA = a[property]?.toString().toLowerCase();
        const valueB = b[property]?.toString().toLowerCase();

        return isAsc
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    });

    setFilteredAndSortedAdresses(sorted);
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && sortDirection === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setSortDirection(newOrder);

    const sorted = [...filteredAdresses].sort((a, b) => {
      if (property === "adr-fact") {
        const addressA = a?.livraison ? a.livraison.adresse : "";
        const addressB = b?.livraison ? b.livraison.adresse : "";

        // Additional check for undefined or null values
        if (addressA === undefined || addressB === undefined) {
          // Handle the case where either addressA or addressB is undefined
          return 0; // or handle it according to your sorting logic
        }

        // Custom sorting logic for non-empty values
        return isAsc
          ? customSort(addressA, addressB)
          : customSort(addressB, addressA);
      }
      if (property === "res-fact") {
        const responsableA = a?.livraison ? a.livraison.responsable : "";
        const responsableB = b?.livraison ? b.livraison.responsable : "";

        // Additional check for undefined or null values
        if (responsableA === undefined || responsableB === undefined) {
          // Handle the case where either addressA or addressB is undefined
          return 0; // or handle it according to your sorting logic
        }

        // Custom sorting logic for non-empty values
        return isAsc
          ? customSort(responsableA, responsableB)
          : customSort(responsableB, responsableA);
      }
      // Handle other properties or use default sorting logic
      const valueA = a[property].toLowerCase();
      const valueB = b[property].toLowerCase();
      return isAsc ? customSort(valueA, valueB) : customSort(valueB, valueA);
    });

    setFilteredAndSortedAdresses(sorted);
  };

  // Custom sorting function for non-empty strings
  const customSort = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  // Helper function to get nested properties

  const handleSearch = () => {
    let filtered = adresses;

    if (searchValue !== "") {
      filtered = adresses.filter((address) =>
        Object.values(address).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }

    setFilteredAdresses(filtered);
    console.log("fff", filtered);
    // Apply sorting on the filtered addresses
    const sorted = [...filtered].sort((a, b) => {
      const valueA = a[orderBy].toLowerCase();
      const valueB = b[orderBy].toLowerCase();
      return sortDirection === "asc"
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    });

    setFilteredAndSortedAdresses(sorted);
  };

  useEffect(() => {
    getAdresses();
  }, []);
  const handleEdit = (id) => {
    // Handle the search logic
    console.log("edittt...");
  };
  return (
    <div className="profil">
      <div className="row">
        <div className="col-md-6">
          <h2>Adresses</h2>{" "}
        </div>
        <div className="col-md-6 text end">
          <div style={{ display: "flex" }}>
            <TextField
              variant="filled"
              type="text"
              style={{ flex: 1 }}
              onChange={(e) => setSearchValue(e.target.value)}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug w-100"
              label="Rechercher une adresse par code, nom..."
            />
            <button
              className="btn-primary"
              color="error" // You can change this to the desired red color
              onClick={handleSearch}
            >
              <img src={Search} alt="search icon" />
            </button>
          </div>
        </div>
        <div className="fetch-data mt-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <div className="d-flex align-items-center">
                      <span>Code</span>
                      <div className="d-flex flex-column ms-1">
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "code" && order === "asc"}
                          direction={"asc"}
                          onClick={() => handleSort("code")}
                        >
                          <img src={SortIcon} alt="icon sort" className="m-1" />
                        </TableSortLabel>
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "code" && order === "desc"}
                          direction={"desc"}
                          onClick={() => handleSort("code")}
                        >
                          <img
                            src={SortIcon}
                            alt="icon sort"
                            className="rotate180 m-1"
                          />
                        </TableSortLabel>
                      </div>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell>
                    <div className="d-flex align-items-center">
                      <span> Responsable livraison</span>
                      <div className="d-flex flex-column ms-1">
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "responsable" && order === "asc"}
                          direction={"asc"}
                          onClick={() => handleSort("responsable")}
                        >
                          <img src={SortIcon} alt="icon sort" className="m-1" />
                        </TableSortLabel>
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "responsable" && order === "desc"}
                          direction={"desc"}
                          onClick={() => handleSort("responsable")}
                        >
                          <img
                            src={SortIcon}
                            alt="icon sort"
                            className="rotate180 m-1"
                          />
                        </TableSortLabel>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex align-items-center">
                      <span> Adresse de livraison</span>
                      <div className="d-flex flex-column ms-1">
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "adresse" && order === "asc"}
                          direction={"asc"}
                          onClick={() => handleSort("adresse")}
                        >
                          <img src={SortIcon} alt="icon sort" className="m-1" />
                        </TableSortLabel>
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "adresse" && order === "desc"}
                          direction={"desc"}
                          onClick={() => handleSort("adresse")}
                        >
                          <img
                            src={SortIcon}
                            alt="icon sort"
                            className="rotate180 m-1"
                          />
                        </TableSortLabel>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex align-items-center">
                      <span>Téléphone</span>
                      <div className="d-flex flex-column ms-1">
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "phone" && order === "asc"}
                          direction={"asc"}
                          onClick={() => handleSort("phone")}
                        >
                          <img src={SortIcon} alt="icon sort" className="m-1" />
                        </TableSortLabel>
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "phone" && order === "desc"}
                          direction={"desc"}
                          onClick={() => handleSort("phone")}
                        >
                          <img
                            src={SortIcon}
                            alt="icon sort"
                            className="rotate180 m-1"
                          />
                        </TableSortLabel>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex align-items-center">
                      <span>Responsable facturation</span>
                      <div className="d-flex flex-column ms-1">
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "res-fact" && order === "asc"}
                          direction={"asc"}
                          onClick={() => handleSort("res-fact")}
                        >
                          <img src={SortIcon} alt="icon sort" className="m-1" />
                        </TableSortLabel>
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "res-fact" && order === "desc"}
                          direction={"desc"}
                          onClick={() => handleSort("res-fact")}
                        >
                          <img
                            src={SortIcon}
                            alt="icon sort"
                            className="rotate180 m-1"
                          />
                        </TableSortLabel>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="d-flex align-items-center">
                      <span>Adresse de facturation</span>
                      <div className="d-flex flex-column ms-1">
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "adr-fact" && order === "asc"}
                          direction={"asc"}
                          onClick={() => handleSort("adr-fact")}
                        >
                          <img src={SortIcon} alt="icon sort" className="m-1" />
                        </TableSortLabel>
                        <TableSortLabel
                          className="label-sort"
                          active={orderBy === "adr-fact" && order === "desc"}
                          direction={"desc"}
                          onClick={() => handleSort("adr-fact")}
                        >
                          <img
                            src={SortIcon}
                            alt="icon sort"
                            className="rotate180 m-1"
                          />
                        </TableSortLabel>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="right" style={{ width: '5%', position: 'sticky', right: 0 }}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAndSortedAdresses?.map((row) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      {row?.code}
                    </StyledTableCell>
                    <StyledTableCell>{row?.responsable}</StyledTableCell>
                    <StyledTableCell>{row?.adresse}</StyledTableCell>
                    <StyledTableCell>{row?.phone}</StyledTableCell>
                    <StyledTableCell>
                      {row?.livraison ? row?.livraison?.responsable: row?.responsable}
                    </StyledTableCell>
                    <StyledTableCell>{
                      row?.livraison
                        ? `${row?.livraison?.entite} ${row?.livraison?.adresse} ${row?.livraison?.zipcode} ${row?.livraison?.ville} ${row?.livraison?.pays}`
                        :`${row?.entite} ${row?.adresse} ${row?.zipcode} ${row?.ville} ${row?.pays}`
                    }
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ width: '5%', position: 'sticky', right: 0 }}>
                      <a className="cta" href={`/profil/adresses/${row?.id}`}>
                        <img src={Edit} alt="edit icon" />
                      </a>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};
export default Adresses;
