import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import closeIcon from '../../assets/icons/Close.svg';
import Check from '../../assets/icons/Check.svg'
 import './modal.scss';
import { useNavigate } from 'react-router-dom';
const ModalDisconnect=({isOpen,setIsOpen,hideModal,btnSecondary, btnPrimary,title,text,function1,function2})=>{
   const navigate=useNavigate()
    return ( 
    <Modal show={isOpen} onHide={hideModal} className="modal-augusta" 
    >
    <ModalBody className="custom-modal-body">
       <button className="btn btn-link float-right" onClick={()=>setIsOpen(false)}>
        <img src={closeIcon} alt="close icon" />  
        </button> 
        <br/>
      <div className='text-center'><img src={Check} alt="check icon"/></div>
        
      <h3 className='text-center mt-16'> {title}  </h3>
      <p className='text-center mt-16'> {text}  </p>
  <div className="row mt-32">    
   <div className="col text-end"><button className='btn-secondary ' onClick={function2} >{btnSecondary}</button></div> 
  <div className="col">
    <button className='btn-primary '  onClick={function1} >{btnPrimary}</button>
    </div>

  
    </div>  
    </ModalBody>

  </Modal>)
    
}
export default ModalDisconnect;