import { useEffect, useState } from "react";
import "./catalogue.scss";
import Layout from "../Layout";
import Search from "../../assets/icons/Search.svg";
import CardComponent from "../../components/card";
/**import icons */
import next from '../../assets/icons/keyboard_arrow_left_24px.svg'
import prevGrey from '../../assets/icons/keyboard_arrow_left2.svg'
import last from '../../assets/icons/last.svg'
import firstGrey from '../../assets/icons/firstGrey.svg'
import firstRed from '../../assets/icons/firstRed.svg'
import nextGrey from '../../assets/icons/nextGrey.svg'
import lastGrey from '../../assets/icons/lastGrey.svg'
import prevRed from '../../assets/icons/prevRed.svg';
import Services from './../../services/global.service';
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
const Catalogue = () => {
  const tabs = [
    { value: "actual", name: "Collection actuelle" },
    { value: "passed", name: "Collections passées" },
  ];
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentTab, setCurrentTab] = useState(tabs[0].value);
  const [selectedOption, setSelectedOption] = useState("date");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState(""); // New state for search text

  const getProducts = () => {
    // Fetch products and set both 'products' and 'filteredProducts' states
    Services.getAll('product/list')
      .then((res) => {
        console.log('res products', res);
        const fetchedProducts = res?.data?.products || [];
            // Filter products based on the currentTab value
      const filteredProducts = currentTab === "actual"
      ? fetchedProducts.filter(product => product?.archive === 0)
      : fetchedProducts.filter(product => product?.archive === 1);
        setProducts(filteredProducts);
        setFilteredProducts(filteredProducts);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    // Call the sorting function when the selection changes
    handleSortChange(event.target.value);
  };

  const handleSortChange = (option) => {
    // Clone the filteredProducts array before sorting
    const sortedProducts = [...filteredProducts];
    if (option === "date") {
      // Sort by date (you can replace this with your actual sorting logic)
      sortedProducts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    } else if (option === "asc") {
      // Sort by price in ascending order
      sortedProducts.sort((a, b) => parseFloat(a.prix) - parseFloat(b.prix));
    } else if (option === "desc") {
      // Sort by price in descending order
      sortedProducts.sort((a, b) => parseFloat(b.prix) - parseFloat(a.prix));
    }
    // Update the filteredProducts state with the sorted array
    console.log(sortedProducts)
    setProducts(sortedProducts);
    // Reset the current page to 1 when the sorting changes
    setCurrentPage(1);
  };

  const handleSearch = () => {
    // Filter products based on the search text
    console.log(searchText) ;
    if(searchText===""){
      getProducts()
    }else{
      const searchTextLowerCase = searchText?.toLowerCase();
    const filtered = products.filter(
      (product) =>
        product.nom.toLowerCase().includes(searchTextLowerCase) ||
        product.reference.toLowerCase().includes(searchTextLowerCase) ||
        product.fournisseur.toLowerCase().includes(searchTextLowerCase)
    );
    console.log(filtered)
    // Update the filteredProducts state with the filtered array
    setProducts(filtered);
    // Reset the current page to 1 when the search changes

    }
    setCurrentPage(1);  
  };

 
  const itemsPerPage = 16;
  
 
  const paginate = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

    // Gestionnaire d'événements pour la touche Entrée du clavier
    const handleSearchInputKeyPress = (e) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    };
  const paginatedData = paginate(products, itemsPerPage, currentPage);

  const totalPages = Math.ceil(products.length / itemsPerPage);
 
useEffect(()=>{
  getProducts();
},[currentTab])
 
  return (

    <Layout>
      
      <div className="container">
        <h1 className="mt-32">Catalogue produit </h1>
        <div className="col-md-12 tabs-container border-tabs mt-38">
          {tabs?.map((el) => {
            return (
              <div
                key={el.value}
                className={`tab ${currentTab === el.value ? "active" : ""}`}
                onClick={() => setCurrentTab(el?.value)}
              >
                <a>{el?.name}</a>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-end">
        <div style={{ display: "flex" }}>
            <TextField
              variant="filled"
              type="text"
              name="searchText"
              style={{ flex: 1 }}
              // InputLabelProps={{
              //   style: {
              //     color: "rgba(18, 16, 16, 1)", // Change the default label color
              //     "&.MuiInputLabel-shrink": {
              //       color: "rgba(251, 38, 59, 1)", // Change the floating label color
              //     },
              //   },
              // }}
              InputLabelProps={{
                sx: { color: "rgba(18, 16, 16, 1)", "&.Mui-focused": { color: "rgba(251, 38, 59, 1)" } },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug w-100"
              label="Rechercher"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={handleSearchInputKeyPress} 
            />
            <button
              className="btn-primary"
              color="error" // You can change this to the desired red color
              onClick={handleSearch}
            
            >
              <img src={Search} alt="search icon" />
            </button>
          </div>
          <div>
          <FormControl variant="filled" sx={{  minWidth: 140,marginLeft:1 }}>
        {/* <InputLabel id="demo-simple-select-filled-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectedOption}
          className="input-aug"
          onChange={handleSelectChange}
        >
          <MenuItem value="date">
          Tri par : Date création
          </MenuItem>
          <MenuItem value={'asc'}>Tri par : Prix croissant</MenuItem>
          <MenuItem value={'desc'}>Tri par : Prix décroissant</MenuItem>
          
        </Select>
      </FormControl>
           
          </div>
        </div>
        <div className="row mt-16">
        {paginatedData.length === 0 ? (
            <p>0 produit disponible</p>
          ) : (
            paginatedData.map((data, index) => (
              <CardComponent key={data.id} data={data} />
            ))
          )}

        </div>
          {paginatedData.length>0 ?
        <nav className="mt-3">
      
        <ul className="pagination">
            <li className="page-item">
              <button
                className="page-link-first"
                onClick={handleFirstPage}
                disabled={currentPage === 1}
              >
                 <img src={currentPage===1 ? firstGrey: firstRed} alt='first icon' />
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link-first"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                 <img src={currentPage===1 ?prevGrey:prevRed} alt='prev icon' />
              </button>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li
                key={index + 1}
                className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link-last"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <img src={currentPage === totalPages ?nextGrey:next} alt='last icon' />
                
              </button>
            </li>
            <li className="page-item">
              <button
                className="page-link-last"
                onClick={handleLastPage}
                disabled={currentPage === totalPages}
              >
                <img src={currentPage===totalPages ?lastGrey:last} alt='last icon' />
              </button>
            </li>
          </ul>
        </nav>:''}
      </div>
    </Layout>
  );
};
export default Catalogue;
