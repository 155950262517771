import { Navigate, Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export default function ProtectedRoutes({ role }) {
  let auth = localStorage.getItem("token");

  if (auth) {
    const decodedToken = jwtDecode(auth);
    const currentTime = Date.now() / 1000;
    console.log("Result", decodedToken.exp < currentTime);
    if (decodedToken.exp < currentTime) {
      localStorage.clear();
      return <Navigate to="/" />;
    }
  } else {
    return <Navigate to="/" />;
  }

  console.log(role);
  if (auth) {
    let rolecheck = localStorage.getItem("role");
    return rolecheck == role ? <Outlet /> : <Navigate to="/" />;
  }
  return <Navigate to="/" />;
}
