
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import BreadcrumbPage from "../../components/breadcrump";
import FormEditAdresse from '../../components/admin/formEditAdresse';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Layout from "../Layout";
import Services from "../../services/global.service";

const EditProfilAdresses = () => {
  const params = useParams();
  const [data, setdata] = useState();

  useEffect(() => {
    Services.getAll("adresses/" + params.id).then(
      (response) => {
        if (response.data) {
          setdata(response.data);
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);

  return (
    <Layout>
      <div className="container mt-32">
         <div className="bread-crumb">
          <span className="bread-item">
            <a className="cta" href="/profil/4">Profil </a> / Modifier adresse
           
          </span>
        </div>
        <div className="mt-24">
          <a href={`/profil/4`} className="cta-retour">
            <KeyboardArrowLeftIcon /> Retour
          </a>
        </div>
        <h1 className="mt-20">Modifier Adresse</h1>
        {data ? <FormEditAdresse data={data} /> : 'loading...'}
      </div>
    </Layout>
  );
};
export default EditProfilAdresses;
