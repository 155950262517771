import Shopping from "../assets/icons/Shoppingwhite.svg";
import Plus from "../assets/icons/Add.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const DetailsP = ({ data }) => {
  const navigate = useNavigate();
  const [showDesc, setShowDesc] = useState(false);
  const [showFiche, setShowFiche] = useState(false);
  console.log("data product", data);
  const addToCart = () => {
    navigate(`/catalogue/commandeMulti/${data?.id}`);
  };
  function convertHtmlToText(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }
  const plainText = data?.description;
  const fiche = data?.fiche;
  return (
    <div className="detail-produit">
      <h1>{data?.nom}</h1>
      <p className="ref-txt">
      <span className="mr-8">  Réf: {data?.reference}</span> |<span className="ml-8">{data?.fournisseur}</span>
      </p>
      <h3 className="mt-16">{data?.prix} € HT</h3>

      {localStorage.getItem("role") != "3" ? (
        <button
          className="btn-primary mt-24"
          onClick={() => {
            addToCart();
          }}
          disabled={data?.archive}
        >
          {" "}
          <img src={Shopping} alt="shop icon" className="mr-8" /> Ajouter au
          panier
        </button>
      ) : (
        ""
      )}

      <hr />
      <div className="d-flex justify-content-between">
        <h3>Description</h3>{" "}
        <button
          className="cta"
          onClick={() => {
            setShowDesc(!showDesc);
          }}
        >
          <img src={Plus} alt="plus icon" className="text-end" />
        </button>
      </div>
      {showDesc ?      <div
      dangerouslySetInnerHTML={{__html: plainText}}
    /> : null}
      <hr />
      <div className="d-flex justify-content-between">
        <h3>Fiche technique</h3>{" "}
        <button
          className="cta"
          onClick={() => {
            setShowFiche(!showFiche);
          }}
        >
          <img src={Plus} alt="plus icon" className="text-end" />
        </button>
      </div>
      {showFiche ?    <div
      dangerouslySetInnerHTML={{__html: fiche}}
    /> :''}
     
    </div>
  );
};
export default DetailsP;
