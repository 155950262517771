import Layout from "../Layout";
import Phone from '../../assets/icons/Phone.svg'
import Email from '../../assets/icons/Email.svg'
import './contact.scss';
const Contact = () => {
  return (
    <Layout>
      <div className="container min-vh-100">
      <div className="bg-contact-container">
          <div className="bg-contact">
            <h1>Contact</h1>
            <p>Vous avez une question ? Un souci ? Contactez-nous :</p>
            <p><img src={Email} alt="mail icon" className="mr-8"/>Par email : <a href="mailto:contact@augusta.com" className="mailto">contact@augusta.com</a></p>
            <p><img src={Phone} alt="mail icon" className="mr-8"/>Par téléphone : 01 44 69 99 19</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Contact;
