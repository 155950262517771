import LayoutAdmin from "../../LayoutAdmin";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Services from "../../../services/global.service";
import BreadcrumbPage from "../../../components/breadcrump";
import FormEditAdresse from '../../../components/admin/formEditAdresse';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const EditAdresses = () => {
  const params = useParams();
  const [data, setdata] = useState();

  useEffect(() => {
    Services.getAll("adresses/" + params.id).then(
      (response) => {
        console.log(response)
        if (response.data) {
          setdata(response.data);
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, []);

  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Adresses"
          page="Liste des adresses"
          sub={"Modifier adresse"}
          parentLink='/admin/adresses'
        />
        <Link to={`/admin/adresses`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>
        <h1 className="mt-20">Modifier Adresse</h1>
        {data ? <FormEditAdresse data={data} /> : 'loading...'}
      </div>
    </LayoutAdmin>
  );
};
export default EditAdresses;
