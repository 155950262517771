import React, { useEffect, useState } from "react";
import ProductImg from "./image 3.png";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../../assets/styles/customstyle.scss";
import Layout from "../Layout";
import More from "../../assets/icons/keyboard_arrow_bottom.svg";
import Delete from "../../assets/icons/Delete.svg";
import Moins from "../../assets/icons/Moins.svg";
import Plus from "../../assets/icons/Plus.svg";
import Services from "../../services/global.service";
import { tableCellClasses } from "@mui/material/TableCell";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SettingsPowerRounded } from "@mui/icons-material";
import ModalAugusta from "../../components/modals/modal";
import axios from "axios";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const OrderSummary = () => {
  const qtePacket = 10;
  const prixU = 10;
  const [rows, setRows] = useState([]);
  const [data, setData] = useState();
  const [expandedRow, setExpandedRow] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const hideModal = () => {
    setOpen(!open);
  };
  const handleRowClick = (rowId) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  const getProduct = async (id) => {
    try {
      const response = await Services.getOne(id, "product");
      return response?.data || {};
    } catch (error) {
      console.error("Error fetching product:", error);
      return {};
    }
  };

  const PassCommande = async () => {
    try {
      // Map the 'rows' state to create the data structure you want to send
      const commandeData = rows.map((row) => {
        const productID = row.produit.id;
        const quantity= row.quantite;
        const prix= row?.quantite * parseFloat(row?.produit?.prix);
        const addressData = row.addresses.map((liv) => ({
          addressID: liv.id,
          quantity: liv.quantity,
          prix:liv.quantity* parseFloat(row?.produit?.prix)
        }));

        return {
          productID,
          quantity,
          prix,
          addresses: addressData,
        };
      });
      const finalData={
        total:totalPrice,
        data:commandeData,
      }
      // Log the data before sending (for debugging purposes)
      console.log("Commande Data:", finalData);
    //  localStorage.removeItem("panier");
      // Send the 'commandeData' to your backend API for processing and saving
      // Use your actual API endpoint and method here
      // Send the 'commandeData' to your backend API using Axios
      Services.create(finalData,'commandes/store')
      .then((res)=>{
        console.log(res?.data?.response)
        setData(res?.data?.response)
        localStorage.removeItem("panier");
        setOpen(!open);
      })
      .catch((err)=>{
        console.log(err)
      })
      
    } catch (error) {
      console.error("Error validating commande:", error);
    }
   
  };
  const calculateTotalPrice = () => {
    console.log(rows);
    const totalPrice = rows.reduce(
      (total, row) => total + parseFloat(row?.produit?.prix) * row.quantite,
      0
    );
    console.log("heloo", totalPrice);
    setTotalPrice(totalPrice);
  };
  const handelReturn=()=>{
    window.history.back();
  }
  useEffect(() => {
    // Fetch the 'panier' from local storage
    const panier = JSON.parse(localStorage.getItem("panier")) || [];

    // Map over the 'panier' array
    const mappedPanierPromise = panier.map(async (item) => {
      // Access the product details asynchronously
      const product = await getProduct(item.id); // Assuming 'id' is the unique identifier for products

      // Access the array of addresses ('addresses') for the current product
      const addresses = item.addresses;

      // Calculate the total quantity for the product
      const totalQuantity = addresses.reduce(
        (total, address) => total + address.quantity,
        0
      );

      // Return the updated item
      return {
        id: item.id,
        produit: product,
        quantite: totalQuantity,
        prixU: prixU,
        addresses: addresses,
      };
    });

    // Use Promise.all to wait for all promises to resolve
    Promise.all(mappedPanierPromise).then((mappedPanier) => {
      // Log the mapped 'panier' array
      console.log(mappedPanier);
      setRows(mappedPanier);
      calculateTotalPrice();
    });
    
  }, [open]);
useEffect(()=>{
  calculateTotalPrice();
},[rows])
  return (
    <Layout>
      
      {open  ? (
        <ModalAugusta
          isOpen={open}
          setIsOpen={setOpen}
          hideModal={hideModal}
          btnPrimary="Voir le catalogue produit"
          primaryLink={'/catalogue'}
          secondaryLink={'/profil/3'}
          btnSecondary="Voir l’historique des commandes"
          title={`Votre commande N° ${data?.reference} a été prise en compte.`}
          text="Vous recevrez un e-mail de confirmation dans les 48h à venir. Si vous ne le recevez pas, pensez à vérifier vos spams."
      />
      ) : (
        ""
      )}
      <div className="container min-vh-100 mt-32">
        <div className="commande mt-24">
          <h1>Récapitulatif de commande</h1>
          {/* Product information at the top */}
          <div className="container mt-24">{/* ... product info ... */}</div>
          {/* Table for displaying products and addresses */}
          <TableContainer component={Paper} className="aug-table">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: "20%" }}>
                    Produit
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "20%" }}>
                    Livraison
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "20%" }}>
                    Facturation
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "20%" }}>
                    Quantité
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "20%" }}>
                    PrixTotal HT
                  </StyledTableCell>
                  <StyledTableCell style={{ width: "10%" }}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <React.Fragment key={index}>
                    {/* Product row */}
                    <StyledTableRow
                      className={expandedRow === index ? "expanded" : ""}
                      hover
                      onClick={() => handleRowClick(index)}
                    >
                      <StyledTableCell className="first-column" >
                        <div className="row ">
                          <h5>{row.produit.nom}</h5>
                          <div className="col">
                            {" "}
                            <p className="text-ref">
                            <span className="mr-8">  Réf: {row?.produit?.reference}</span> |
                            <span className="ml-8"> {row?.produit?.fournisseur}</span>
                            </p>
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell className="second-column" >
                        {row?.addresses?.length} adresses
                      </StyledTableCell>
                      <StyledTableCell className="third-column" >
                        {row?.addresses?.length} entitées de facturations
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "20%" }}>
                        {row?.quantite}{" "}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: "20%" }}>
                        {parseFloat(row?.produit?.prix) * row?.quantite} €
                      </StyledTableCell>

                      <StyledTableCell style={{ width: "10%" }}>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRowClick(index);
                          }}
                        >
                          <img
                            src={More}
                            alt="more icon"
                            className={expandedRow === row.id ? "open" : ""}
                          />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                    {/* Expanded row with addresses */}
                    {expandedRow === index && (
                      <TableRow>
                        <StyledTableCell colSpan={6}>
                          <Accordion expanded={true} elevation={0}>
                            <AccordionDetails>
                              <Table>
                                <TableBody>
                                  {row.addresses.map((liv) => (
                                    <TableRow key={liv.adresse}>
                                      <StyledTableCell style={{ width: "20%" }}>
                                        {liv.code}  {liv.entite}
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: "20%" }}>
                                        <div className="d-flex p-3">
                                          {liv?.quantity}
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: "20%" }}>
                                        {row?.produit?.prix} €
                                      </StyledTableCell>
                                      <StyledTableCell style={{ width: "20%" }}>
                                        {liv.quantity * row?.produit?.prix} €
                                      </StyledTableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </AccordionDetails>
                          </Accordion>
                        </StyledTableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="fixedBanner row">
        <div className="col-md-6">
          <button className="btn-secondary" onClick={handelReturn}>Annuler</button>
        </div>
        <div className="col-md-6 text-end">
          <h5>
            Total: {totalPrice} € HT
            <button
              className="ml-10 btn-primary"
              onClick={() => {
                PassCommande();
              }}
            >
              Passer la commande
            </button>
          </h5>
        </div>
      </div>
    </Layout>
  );
};

export default OrderSummary;
