import LayoutAdmin from "../../LayoutAdmin";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Services from "../../../services/global.service";
import BreadcrumbPage from "../../../components/breadcrump";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import UserFormEdit from './../../../components/admin/formEditUser';

const EditUsers = () => {
  const params = useParams();
  const [data, setdata] = useState();

  useEffect(() => {
    Services.getOne(params.id, "users/getone").then(
      (response) => {
        console.log(response);
        if (response.data) {
          setdata(response.data);
        }
        // navigate("/catalogue");
      },
      (error) => {}
    );
  }, [params.id]);

  return (
    <LayoutAdmin>
      <div className="container">
        <BreadcrumbPage
          parent="Utilisateurs"
          page="Liste des utilisateurs"
          sub={"Modifier utilisateur"}
          parentLink='/admin/users'
        />
        <Link to={`/admin/users`} className="cta-retour">
          <KeyboardArrowLeftIcon /> Retour
        </Link>
        <h1 className="mt-20">Modifier utilisateur</h1>
        {data ? <UserFormEdit usr={data} /> : "Aucun produit"}
      </div>
    </LayoutAdmin>
  );
};
export default EditUsers;
