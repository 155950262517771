import Layout from "../Layout";
import Retour from "../../assets/icons/keyboard_arrow_left.svg";
import Search from "../../assets/icons/Search.svg";
import Plus from "../../assets/icons/Plus.svg";
import Moins from "../../assets/icons/Moins.svg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";

import "./commande.scss";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import BreadcrumbPage from "../../components/breadcrump";
import ModalAugusta from "../../components/modals/modal";
import { TextField } from "@mui/material";
const Commande = () => {
  const navigate = useNavigate();

  const [details, setDetails] = useState();
  const [adresses, setAdresses] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const getAdresses = () => {
    Services.getAll('adresses/owner')
      .then((res) => {
        console.log(res?.data);
        const sortedAddresses = res?.data?.sort((a, b) => a.code.localeCompare(b.code));
        const addressesWithQuantities = sortedAddresses.map((address) => ({
          ...address,
          quantity: 0,
        }));
        setAdresses(addressesWithQuantities);
        console.log('adr', adresses);
      })
      .catch((err) => {
        console.error('Error fetching addresses:', err);
      });
  };
  
  const params = useParams();
  const [searchValue, setSearchValue] = useState("");
  const handleNavigateOld = () => {
    // Use the updated adresses state from the callback
    const updatedAdresses = adresses.filter((el) => el.quantity !== 0);
  
    console.log('click', updatedAdresses);
  
    // Get existing items from localStorage
    console.log('here')
    const existingItems = JSON.parse(localStorage.getItem('panier')) || [];
    // Check if the product with the same ID already exists
    const existingProductIndex = existingItems.findIndex(
      (item) => item.product === params.id
    );
  console.log('existingProductIndex',existingProductIndex);
  if (existingProductIndex !== -1) {
    // If the product exists, update the quantity for each address or add new addresses
    updatedAdresses.forEach((updatedAddress) => {
      const existingAddressIndex = existingItems[existingProductIndex].rows.findIndex(
        (existingAddress) => existingAddress.id === updatedAddress.id
      );

      if (existingAddressIndex !== -1) {
        // If the address exists, update the quantity
        existingItems[existingProductIndex].rows[existingAddressIndex].quantity +=
          updatedAddress.quantity;
      } else {
        // If the address doesn't exist, add a new entry
        existingItems[existingProductIndex].rows.push(updatedAddress);
      }
    });
  } else {
    // If the product doesn't exist, add a new entry
    const data = {
      rows: updatedAdresses,
      product: params.id,
    };

    existingItems.push(data);
  }
    
    localStorage.setItem('panier', JSON.stringify(existingItems));
  
    setIsOpen(true);
  };
  const handleNavigate = () => {
    // Use the updated adresses state from the callback
    const updatedAdresses = adresses.filter((el) => el.quantity !== 0);
  
    console.log('click', updatedAdresses);
  
    // Get existing items from localStorage
    console.log('here')
    const existingItems = JSON.parse(localStorage.getItem('panier')) || [];
    // Check if the product with the same ID already exists
    const existingProductIndex = existingItems.findIndex(
      (item) => item.id == params.id
    );
  console.log('existingProductIndex',existingProductIndex);
  if (existingProductIndex !== -1) {
    // If the product exists, update the quantity for each address or add new addresses
    updatedAdresses.forEach((updatedAddress) => {
      const existingAddressIndex = existingItems[existingProductIndex].addresses.findIndex(
        (existingAddress) => existingAddress.id === updatedAddress.id
      );

      if (existingAddressIndex !== -1) {
        // If the address exists, update the quantity
        existingItems[existingProductIndex].addresses[existingAddressIndex].quantity +=
          updatedAddress.quantity;
      } else {
        // If the address doesn't exist, add a new entry
        existingItems[existingProductIndex].addresses.push(updatedAddress);
      }
    });
  } else {
    // If the product doesn't exist, add a new entry
    // const data = {
    //   rows: updatedAdresses,
    //   product: params.id,
    // };
 // If the product doesn't exist, add a new entry
    const data = {
      id: params.id,
      product: params.id,
      addresses: updatedAdresses,
    };
    existingItems.push(data);
  }
    
    localStorage.setItem('panier', JSON.stringify(existingItems));
  
    setIsOpen(true);
  };

  const getDetails = () => {
    Services.getOne(params.id, "product").then((res) => {
      console.log("res details product", res?.data);
      setDetails(res?.data);
    });
  };
  const handleSearch = () => {
    let filtered = adresses;

    if(searchValue!==''){ 
      filtered = adresses.filter((address) =>
      Object.values(address).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }
  setAdresses(filtered);
}
 
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
 
  const handleReturn=()=>{
    window.history.back();
  }
  const handleCounter = (address, action) => {
    setAdresses((prevAddresses) => {
      return prevAddresses.map((prevAddress) => {
        if (prevAddress.id === address.id) {
          // Update the quantity property based on the action
          const newQuantity =
            action === 'plus'
              ? prevAddress.quantity + parseInt(details?.quantiteParCarton)
              : Math.max(prevAddress.quantity - parseInt(details?.quantiteParCarton), 0);
          return { ...prevAddress, quantity: newQuantity };
        }
        return prevAddress;
      });
    });
  };
  
  const hideModal = () => {
    setIsOpen(!isOpen);
  };
   // Function to calculate the total
   const calculateTotal = () => {
    return adresses?.reduce((total, row) => {
      return total + parseFloat(details?.prix) * row?.quantity;
    }, 0);
  };
  useEffect(() => {
    getDetails();
    getAdresses();
  }, [searchValue]);
  return (
    <Layout>
      <div className="container min-vh-100 mt-32">
        {isOpen ? (
          <ModalAugusta
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            hideModal={hideModal}
            btnPrimary="Voir mon panier"
            primaryLink={'/panier'}
            secondaryLink={'/catalogue'}
            btnSecondary="Continuer mes achats"
            title="Les produits ont bien été ajoutés au panier."
            text=""
          />
        ) : (
          ""
        )}
        <div className="bread-crumb">
          <span className="bread-item">
            <a className="cta" href="/catalogue">Catalogue Produit </a> /
            <a className="cta" href={`/catalogue/${params?.id}`}>{details?.nom} </a> / Commande multi-adresses{" "}
          </span>
        </div>
        <div className="mt-24">
          <a className="cta-retour" href="/catalogue">
            <img src={Retour} alt="retour icon" className="mr-8" /> Retour
          </a>
        </div>

        <div className="commande mt-24">
          <h1>Commande multi-adresses</h1>
          <div className="row mt-1">
            <div className="col-md-6">
              <p>
                Sélectionnez la quantité souhaitée pour chaque destinataire.
              </p>
            </div>
            <div className="col-md-6">
              {" "}
              <div style={{ display: "flex" }}>
            <TextField
              variant="filled"
              type="text"
              style={{ flex: 1 }}
              onChange={(e) => setSearchValue(e.target.value)}
              InputLabelProps={{
                style: {
                  color: "rgba(18, 16, 16, 1)", // Change the default label color
                  "&.MuiInputLabel-shrink": {
                    color: "rgba(251, 38, 59, 1)", // Change the floating label color
                  },
                },
              }}
              sx={{
                "& .MuiInput-root": {
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottom: "none", // Remove the bottom border on hover
                  },
                },
              }}
              className="input-aug w-100"
              label="Rechercher une adresse par code, nom..."
            />
            <button
              className="btn-primary"
              color="error" // You can change this to the desired red color
              onClick={handleSearch}
            >
              <img src={Search} alt="search icon" />
            </button>
          </div>
            </div>
          </div>
          <div className="container mt-24">
            <TableContainer component={Paper} className="aug-table">
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Destinataire</StyledTableCell>
                    <StyledTableCell>Quantité</StyledTableCell>
                    <StyledTableCell>Prix unitaire HT</StyledTableCell>
                    <StyledTableCell>Prix Total HT</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adresses?.map((row) => (
                    <StyledTableRow key={row?.id}>
                      <StyledTableCell  scope="row">
                      {row.code} {row.entite}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="d-flex p-2">
                          <button
                            className="cta-qte p-2"
                            //disabled={row?.quantity===0}
                            onClick={() => handleCounter(row, "minus")}
                          >
                            <img src={Moins} alt="minus icon" />
                          </button>
                          <button className="cta-qte p-2">
                            {row?.quantity}
                          </button>
                          {/* <TextField id="filled-basic "  variant="filled" value={row.quantite} /> */}
                          <button
                            className="cta-qte p-3"
                            onClick={() => handleCounter(row, "plus")}
                          >
                            <img src={Plus} alt="plus icon" />
                          </button>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{details?.prix} €</StyledTableCell>
                      <StyledTableCell className="totale">
                        {parseFloat(details?.prix) * row?.quantity} €
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="fixedBanner row">
        <div className="col-md-6">
          <button className="btn-secondary" onClick={handleReturn}>Annuler</button>
        </div>

        <div className="col-md-6 text-end">
          <h5>
            Total: {calculateTotal()}  € HT
            <button className="ml-10 btn-primary" onClick={handleNavigate} disabled={calculateTotal()<=0}>
              Ajouter au panier
            </button>
          </h5>
        </div>
      </div>
    </Layout>
  );
};
export default Commande;
