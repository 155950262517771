import '../assets/styles/card.scss';
import React, { useState, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
const CardCommande=({data})=>{
  const [isOpen, setIsOpen]= useState(false)
  const buttonRef = useRef(null);
  const navigate=useNavigate()
  const handleCardClick = () => {
    // Navigate to the product page
    navigate(`/profil/detailCommande/${data.id}`);
  }; 
  
  const hideModal=()=>{
    setIsOpen(!isOpen)
  }
 

  return (
    <div className="col-md-6 mb-4">
       
      <Card
        style={{ width: '100%' }}
        className="card-aug historique"
        onClick={handleCardClick}
      >
        <Card.Body>
          <Card.Title>
            <div className="row">
              <div className="col"><h4>{data?.reference}</h4></div>
              
             <div className="col"><h4 className='text-end'>{data?.total} € HT</h4></div> 
            </div>
            
          </Card.Title>
          <Card.Text className='ref-txt'>
           {new Date(data?.created_at).toLocaleDateString('fr-FR')}
          </Card.Text> 
          <Card.Text >
          <p className='noteCommande'>{data?.note}</p>

          </Card.Text>
         
        </Card.Body>
      </Card>
    </div>
  );
}
export default CardCommande;