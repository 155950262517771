import Layout from "../Layout";
import Phone from '../../assets/icons/Phone.svg'
import Email from '../../assets/icons/Email.svg'
import './contact.scss';
const Mentions = () => {
  return (
    <Layout>
      <div className="container min-vh-100 mt-32">
      <div className="mentions">
          <h1>Identification de l'éditeur </h1>
          <p>Le présent site internet (ci-après le « Site ») est édité par : <br/>
          AUGUSTA, SARL au capital social de 262 500 euros inscrite au Registre du commerce et des <br/>
          sociétés de Paris B sous le numéro 438 577 264, dont le siège social est situé au 24 rue Chaptal, 
          75009 Paris. <br/>
          Directeur de la publication : M. Julien Panthou <br/>
          E-mail :  <a href="mailto:contact@augusta.fr" className="cta" >contact@augusta.fr</a><br/>
          Téléphone :+33 1 44 69 99 19</p>
          <ol>
            <li><h2>Hébergeur du site</h2></li>
            <p>Le Site est hébergé sur les serveurs de OVH, 2 rue Kellermann - 59100 Roubaix – France. 
            Contact : <a href='https://www.ovhcloud.com/fr/contact/' className='cta'>www.ovhcloud.com/fr/contact</a> </p> 
            <li><h2>Respect de la propriété intellectuelle</h2> </li>
          <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, 
          séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées 
          pour faire fonctionner le Site et plus généralement tous les éléments reproduits ou utilisés sur le 
          Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle. 
          Ils sont la propriété pleine et entière de l’éditeur ou de ses partenaires, sauf mentions 
          particulières. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme 
          que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans 
          l’accord préalable et écrit de l’éditeur, sont strictement interdites. 
          La reprise de tout ou partie de ce contenu nécessite l’autorisation préalable de l’éditeur ou du 
          titulaire des droits sur ce contenu.</p> 
          <li><h2>Crédits</h2></li> 
          <p>Toutes les photographies à but illustratif sont tirées d'échantillons de productions 
          d'AUGUSTA ou de maquettes visuelles générées par AUGUSTA et ses partenaires. </p>
            </ol>
        </div>
      </div>
    </Layout>
  );
};
export default Mentions;
