
import Footer from "../components/footer";
import Header from "../components/header";
import SideBar from "../components/sidebar";
function Layout(props){
return(
    <div className="min-vh-100">
   
        <Header />
       <div className="col-12 min-vh-80">
        {props.children}
        </div> 
        <Footer admin={false}/>
    </div>

)
}
export default Layout;