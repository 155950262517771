import Layout from "../Layout";
import Phone from "../../assets/icons/Phone.svg";
import Email from "../../assets/icons/Email.svg";
import "./contact.scss";
const Pc = () => {
  return (
    <Layout>
      <div className="container min-vh-100 mt-32">
        <div className="mentions">
          <h1>Politique de confidentialité</h1>

          <p className="mt-24">
            Nous vous demandons de bien vouloir lire attentivement la présente
            politique de confidentialité pour comprendre comment sont
            collectées, traitées et conservées vos données personnelles de
            l’application web AUGUSTA.
          </p>

          <p>
            Au titre de la présente politique de confidentialité, le responsable
            du traitement de la collecte et du traitement de vos données
            personnelles est la société AUGUSTA, société par actions simplifié
            au capital social de 262.500 euros inscrite au Registre du commerce
            et des sociétés de Paris sous le numéro 438 577 264, dont le siège
            social est situé au 24 rue Chaptal, 75009 Paris (ci-après la «
            Société » ou « nous »).
          </p>

          <p>
            Nous sommes joignables à l’adresse de courrier électronique suivant
            pour toute réclamation ou question concernant cette politique de
            confidentialité :{" "} <a href="mailto:contact@augusta.fr" className="cta">
            contact@augusta.fr
          </a>
          </p>
         
          <p> </p>
          <ol>
            <li>
             
                {" "}
                Quelles sont les données personnelles que nous collectons ?
             
            </li>
           
              
           
          </ol>
          <p>
                {" "}
               a. Données collectées via l’application web afin de constituer
                votre compte client
              </p>
          <p>
            Nous collectons les données suivantes de connexion et
            d’identification :
          </p>
          <ul>
            <li  aria-level="1">
              <p>Nom et prénom</p>
            </li>
            <li  aria-level="1">
              <p>Email</p>
            </li>
            <li  aria-level="1">
              <p>Mot de passe</p>
            </li>
            <li  aria-level="1">
              <p>Poste</p>
            </li>
            <li  aria-level="1">
              <p>Business unit</p>
            </li>
          </ul>
          <ol start="2">
            <li>
             
                {" "}
                Pour quelles finalités et bases juridiques collectons-nous vos
                données personnelles ?
              
            </li>
          </ol>
          <p>
            Nous collectons, traitons et conservons vos données personnelles
            pour les finalités, et sur le fondement des bases juridiques,
            suivantes :
          </p>
          <ul>
            <li  aria-level="1">
              <p>
                Lorsque vous utilisez webshop.augusta.fr, les données
                personnelles vous concernant seront traitées et conservées pour
                permettre de vous authentifier et utiliser cette même plateforme
                web. Ce traitement de vos données personnelles est ainsi
                nécessaire à l’exécution du contrat d’utilisation passé avec
                notre Société lorsque vous décidez d’utiliser la plateforme.
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Dans le cadre de la bonne administration de l’application, nous
                pouvons également collecter vos données personnelles afin de
                vous envoyer des emails concernant votre inscription sur la
                plateforme, des courriers d’information, des notifications
                techniques, des mises à jour, des alertes de sécurité. Le
                traitement de vos données personnelles est ainsi à ce titre
                également nécessaire à l’exécution de votre contrat
                d’utilisation mais également fondé sur notre intérêt légitime à
                administrer la plateforme.‍
              </p>
            </li>
          </ul>
          <ol start="3">
            <li>
          
                {" "}
                Qui seront les personnes pouvant avoir accès à vos données
                personnelles ?
            
            </li>
          </ol>
          <p>
            Les données à caractère personnel vous appartenant ne seront
            accessibles que par les personnes autorisées au sein de la Société
            et aux fins strictement nécessaires à leurs missions respectives
            (notamment notre service client et notre service commercial).
          </p>

          <p>
            Nous serons par ailleurs amenés à partager vos données personnelles
            à des tiers dans les situations suivantes :
          </p>

          <p>
            Vos données personnelles seront stockées au sein des serveurs de
            notre hébergeur OVH nous permettant de fournir un accès à la
            plateforme dont les serveurs sont localisés en Europe.
          </p>

          <p>
            Vos données personnelles seront également rendues accessibles à nos
            prestataires techniques, dont notamment nos prestataires de
            transport et nos fabricants, notre prestataire CRM, notre
            prestataire de routage d’emails et SMS. Certains de ces prestataires
            peuvent par ailleurs être situés aux Etats-Unis, pays tiers non
            reconnu par la Commission européenne comme disposant d'un niveau de
            protection adéquat en matière de protection des données à caractère
            personnel. Nous avons toutefois pris soin de vérifier que ces
            prestataires respectent la confidentialité de vos données
            personnelles et avons conclu avec eux les clauses contractuelles
            types de la Commission Européenne afin d’encadrer ce transfert de
            données vers les Etats Unis.
          </p>

          <p>
            L’ensemble des prestataires susvisés accéderont à vos données
            personnelles dans le respect des conditions contractuelles signées
            avec notre Société, conformes à la réglementation applicable en
            matière de protection des données à caractère personnel, et ne
            pouvant déroger aux conditions de la présente politique de
            confidentialité.
          </p>
          <ol start="4">
            <li>
               Comment seront hébergées vos données ?
            </li>
          </ol>
          <p>
            Nous veillons à sécuriser les données personnelles des utilisateurs
            de manière adéquate et appropriée et avons pris les précautions
            utiles afin de préserver et faire préserver par notre hébergeur la
            sécurité et la confidentialité des données et notamment empêcher
            qu’elles ne soient déformées, endommagées ou communiquées à des
            personnes non autorisées.
          </p>

          <p>
            Le cas échéant, toute éventuelle faille de sécurité vous sera
            notifiée dans le strict respect de la réglementation applicable. Vos
            données de connexion et d’identification seront immédiatement
            détruites par notre Société à compter de la suppression de votre
            compte utilisateur sur la plateforme. Vos données de contact seront
            toutefois conservées par notre Société pour une durée maximum de 3
            ans à compter de votre dernier contact auprès de nous, sauf
            opposition de votre part. L’entreprise à laquelle vous appartenez et
            qui vous a permis d’utiliser notre application peut par ailleurs
            être amenée à conserver des données vous concernant en tant que
            responsable de traitement. Il convient alors dans cette hypothèse
            d’exercer directement auprès de votre entreprise toute demande
            d’accès et/ou suppression de vos données à caractère personnel.
          </p>
          <ol start="5">
            <li>
             Exercice de vos droits
            </li>
          </ol>
          <p>
            Conformément à la réglementation européenne en matière de
            protections des données à caractère personnel, vous bénéficiez des
            droits suivants :
          </p>
          <ul>
            <li  aria-level="1">
              <p>
                Un droit d’accès vous permettant à tout moment de savoir si vos
                données personnelles sont ou ne sont pas traitées par nos
                services et lorsqu’elles le sont, d’avoir accès aux dites
                données personnelles et aux informations requises par la loi
                concernant les modalités de traitement de ces données,
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Un droit de rectification vous permettant, de demander que soit
                corrigée dans les meilleurs délais toute inexactitude concernant
                les données personnelles vous concernant,
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Un droit à l’effacement, vous permettant de demander dans les
                meilleurs délais à ce que vos données personnelles soient
                effacées lorsque (i) leur conservation n’est plus nécessaire au
                regard des finalités pour lesquelles elles ont été collectées
                (ii) vous vous êtes opposés au traitement et souhaiter en
                conséquence qu’elles soient effacées (iii) les données ont fait
                l'objet d'un traitement illicite ; (iv) les données doivent être
                effacées pour respecter une obligation légale qui est prévue
                soit par le droit de l’Union Européenne soit par le droit
                français,
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Un droit à la limitation du traitement de vos données
                personnelles (i) lorsque vous contestez l’exactitude des données
                personnelles collectées pendant la durée permettant de vérifier
                l’exactitude de celles-ci, (ii) lorsque suite à un traitement
                établi comme non conforme, vous préférez la limitation du
                traitement à l’effacement complet des données (iii) lorsque les
                données à caractère personnel ne sont plus nécessaires aux fins
                du traitement mais que celles-ci sont encore nécessaires pour la
                constatation, l’exercice ou la défense de droits en justice ;
                (iv) lorsque vous vous êtes opposés au traitement et que vous
                souhaitez une limitation du traitement pendant la durée
                permettant de vérifier si le motif légitime invoqué se justifie,
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Un droit à la portabilité vous permettant de recevoir vos
                données personnelles dans un format structuré, couramment
                utilisé et lisible par machine, ou à demander que ces données
                personnelles soit transmises à un autre responsable de
                traitement, sous réserve que cette demande de portabilité porte
                sur des données personnelles que vous avez directement fournies
                et vous concernant et que cette demande de portabilité ne porte
                pas atteinte aux droits et libertés de tiers,
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Un droit d’opposition au traitement de vos données personnelles
                pour des raisons tenant à votre situation particulière et il
                sera alors mis fin au traitement en cause sauf s’il existe des
                motifs légitimes et impérieux en justifiant la poursuite en
                conformité avec la règlementation applicable,
              </p>
            </li>
            <li  aria-level="1">
              <p>
                Le droit de définir des directives relatives à la conservation,
                à l'effacement et à la communication de vos données à caractère
                personnel à compter de votre décès,
              </p>
            </li>
          </ul>
          <p>
            Dans le cas où le traitement de vos données personnelles est fondé
            sur votre consentement, vous avez également le droit de retirer ce
            consentement à tout moment.
          </p>

          <p>
            Ces droits pourront être exercés en nous contactant directement à
            l’adresse email suivante :{" "} <a href="mailto:contact@augusta.fr" className="cta">
            contact@augusta.fr
          </a>
          </p>
         
          <p> </p>

          <p>
            Les réclamations relatives à l’utilisation de vos données
            personnelles peuvent le cas échéant également être adressées à la
            Commission Nationale de l’Informatique et des Libertés, 3 Place de
            Fontenoy - TSA 80715 - 75334 PARIS CEDEX
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default Pc;
